<template>
  <div style="background: #fff; padding-bottom: 40px; width: 100%">
    <!-- 单选框区域 -->
    <div class="radio-title">是否中标</div>
    <el-radio-group
      :value="formData.isWin"
      style="margin-bottom: 20px; padding: 0 20px"
      @input="onRadioChange"
      :disabled="!canWrite"
    >
      <el-radio label="loser">未中标</el-radio>
      <el-radio label="winner"> 已中标 </el-radio>
    </el-radio-group>
    <!-- 已中标附件上传区域 -->
    <UploadList
      style="min-height: 500px"
      v-model="uploadData"
      fileCode="user_fj"
      v-if="formData.isWin == 'winner'"
      :staffId="formData.fkId"
      :disabled="!canWrite"
    ></UploadList>
  </div>
</template>

<script>
import { getInstanceByDom } from 'echarts'
export default {
  name: 'WinResult',
  components: {
    UploadList: () => import('@/components/upload/ContractList.vue'),
  },
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    //当前页面输入框输入状态
    editType: {
      type: String,
      default: '',
    },
  },
  watch: {
    detailData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.formData.isWin = newVal.isWin
        this.formData.fkId = newVal.fkId
        // 将详情中的附件数据传入uploadData
        this.getDetailFileList(newVal)
      },
    },
  },
  computed: {
    canWrite() {
      switch (this.editType) {
        case 'add':
          return true
        case 'edit':
          return true
        case 'detail':
          return false
        case 'bidEdit':
          return false
        default:
          return false
      }
    },
  },
  data() {
    return {
      formData: {
        isWin: '',
        fkId: '',
      },
      uploadData: [],
    }
  },
  methods: {
    onRadioChange(e) {
      this.formData.isWin = e
      if (this.uploadData.length > 0) {
        this.uploadData = []
      }
    },
    getDetailFileList(newVal) {
      if (newVal.fileList && newVal.fileList.length > 0) {
        let detailFileList = newVal.fileList.map(item => {
          return {
            attachmentName: item.fileName,
            attachmentPath: item.filePath,
            id: item.id,
            remark: item.remark,
            type: item.type,
            staffId: newVal.fkId,
            bidType: item.bidType,
          }
        })
        this.uploadData = [...detailFileList]
      }
    },
    save() {
      // if (this.formData.isWin == 'winner' && this.uploadData.length == 0) {
      //   this.$message.warning('请上传附件')
      //   return 'warning'
      // }
      //过滤出被删除的附件数组
      let deleteArr = (this.detailData.fileList || [])
        .filter(
          item =>
            !this.uploadData
              .filter(x => x.id)
              .map(y => y.id)
              .includes(item.id)
        )
        .map(item => item.id)
      let fileList = this.uploadData.map(item => {
        return {
          fkId: item.staffId,
          fileName: item.attachmentName,
          filePath: item.attachmentPath,
          id: item.id,
          remark: item.remark,
          type: 'ZBFJ',
          bidType: 'bidFile',
        }
      })
      this.formData.fileList = fileList
      this.formData.deleteFileList = deleteArr
      return this.formData
    },
  },
}
</script>

<style lang="scss" scoped>
.radio-title {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0 5px;
}
</style>
